<template>
  <div class="public">
    <v-footer dark height="auto" color="#152939" class="pg-bottom-60 mt-4">
      <v-container class="pa-0">
        <div class="pg-lr-60">
          <v-row
            :class="{ 'd-flex flex-column': $vuetify.breakpoint.smAndDown }"
          >
            <v-col cols="12" xs="12" sm="12" md="5" class="pg-top-60">
              <v-card dark flat color="#152939">
                <div>
                  <div class="d-flex">
                    <img
                      src="/assets/public/ucclogo.png"
                      style="top: -12px; position: relative"
                      alt="logo"
                    />
                    <span style="font-size: 18px; padding-left: 10px"
                      >Urban Care Community</span
                    >
                  </div>
                  <a
                    v-for="profile in profiles"
                    :key="profile.icon"
                    :href="profile.link"
                    target="_blank"
                  >
                    <v-btn
                      :key="profile.icon"
                      class="mx-1 white--text"
                      color="white"
                      icon
                    >
                      <v-icon size="24px">{{ profile.icon }}</v-icon>
                    </v-btn>
                  </a>
                  <br />
                  <br />
                  <p style="font-size: 16px">
                    <b>Newsletter</b>
                  </p>
                  <form class="form-inline">
                    <div class="form-group">
                      <input
                        type="email"
                        class="form-control"
                        id="inputPassword2"
                        placeholder="Email Address"
                      />&nbsp;
                      <router-link
                        to="/"
                        :class="{
                          'd-flex pt-3': $vuetify.breakpoint.smAndDown,
                        }"
                      >
                        <v-btn light color="white ">Subscribe</v-btn>
                      </router-link>
                    </div>
                  </form>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value
                      id="defaultCheck1"
                    />
                    <label class="form-label pa-1">
                      <label for="defaultCheck1">
                        agree to Urban Care Community's
                      </label>
                      <router-link
                        class="terms-link"
                        style="color: #fff"
                        to="/terms-and-conditions"
                        target="_blank"
                      >
                        Terms & Conditions
                      </router-link>
                      and
                      <router-link
                        class="terms-link"
                        style="color: #fff"
                        to="/ucc-privacy-policy"
                        target="_blank"
                      >
                        Privacy Policy
                      </router-link>
                    </label>
                  </div>
                </div>
              </v-card>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="2" class="pg-top-60">
              <v-card dark flat color="#152939">
                <p>
                  <b>GET STARTED</b>
                </p>
                <router-link to="/care-provider-signup"
                  >Care Providers</router-link
                >
                <br />
                <router-link to="/qmo-signup">Monitoring Officer</router-link>
                <br />
                <router-link to="/care-worker-and-nurses-signup"
                  >Carers & Nurses</router-link
                >
                <br />
                <router-link to="/contact-us">Contact Us</router-link>
                <br />
                <router-link to="/faq">FAQ</router-link>
              </v-card>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="2" class="pg-top-60">
              <v-card dark flat color="#152939">
                <p>
                  <b>COMPANY</b>
                </p>
                <router-link to="/about">About Us</router-link>
                <br />
                <router-link to="/covid19">COVID19 Reponse</router-link>
                <br />
                <router-link to="/ucc-privacy-policy">Privacy</router-link>
                <br />
                <router-link to="/terms-and-conditions"
                  >Terms and Conditions</router-link
                >
              </v-card>
            </v-col>
            <v-col
              cols="12"
              xs="12"
              sm="12"
              md="3"
              lg="3"
              xl="3"
              class="pg-top-60"
            >
              <v-card dark flat color="#152939">
                <p>
                  <b>RESOURCES</b>
                </p>
                <router-link to="/technology-enabled-care"
                  >Technology Enabled Care</router-link
                >
                <br />
                <router-link to="/learning-disability-care-guide"
                  >LD Care Guide</router-link
                >
                <br />
                <router-link to="/live-in-care-guide"
                  >Live in Care Guide</router-link
                >
                <br />
                <router-link to="/funding-care-guide"
                  >Funding Care Guide</router-link
                >
                <br />
                <router-link to="/dementia-care-guide"
                  >Dementia Guide</router-link
                >
                <br />
                <router-link to="/nursing-care-guide"
                  >Nursing Care Guide</router-link
                >
                <br />
                <router-link to="/policies">Policies</router-link>
              </v-card>
            </v-col>
          </v-row>
        </div>
        <div class="pg-top-30">
          <p :class="{ 'p-small': $vuetify.breakpoint.smAndDown }">
            Urban Care Community is registered in England and Wales Number
            10739712. The Care Quality Commission (CQC) defines companies like
            Urban Care Community as an introductory agency pursuant to the
            Health & Social Care Act 2008
          </p>
        </div>
      </v-container>
    </v-footer>
  </div>
</template>
<script>
export default {
  data: () => ({
    profiles: [
      {
        icon: "mdi-facebook",
        link: "https://www.facebook.com/urbancarecommunity",
      },
      { icon: "mdi-twitter", link: "https://twitter.com/ucarecommunity1" },
      {
        icon: "mdi-instagram",
        link: "https://www.instagram.com",
      },
      {
        icon: "mdi-youtube",
        link: "https://www.youtube.com/channel/UC1SI0kIPmmA-TtobYZad-5w",
      },
      {
        icon: "mdi-linkedin",
        link: "https://uk.linkedin.com/in/urbancare-community-31561b142",
      },
    ],
  }),
};
</script>
<style lang="scss" scoped>
a {
  text-decoration: none;
  border: 0px;
  color: white !important;
  font-size: 16px !important;
  font-weight: 300;
  line-height: 24px;

  a:hover {
    text-decoration: none;
    border: 0px;
    color: #d1d1d1 !important;
    font-size: 16px !important;
    font-weight: lighter;
    line-height: 24px;
  }
}
input[type="text"] {
  width: 100%;
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: groove;
  font-size: 42px;
  background-color: white;
  padding: 12px 20px 12px 40px;
}
.form-control {
  padding: 5px;
  background-color: #fff;
}
.terms-link {
  cursor: pointer;
  padding-bottom: 5px;
  border-bottom: 1px solid #fff;
}
.form-check-input {
  padding: 02px;
}
.form-inline {
  padding-bottom: 10px;
}
.form-label {
  font-weight: 300;
  font-size: 16px;
}
.no-outline:focus {
  outline: none;
}
p {
  font-size: 16px !important;
  line-height: 24px;
  font-weight: 300 !important;
  letter-spacing: 0px;
}
@media (max-width: 700px) {
  .p-small {
    font-size: 14px;
    line-height: 24px;
    font-weight: lighter;
    padding: 0px 30px;
  }
}
</style>
