import { ClientAPI } from "@apiV2";
import { CarerAPI } from "@apiV2";
import { authServiceInstance } from "@auth/index";
// import cFetch from "../../helpers/cFetch";

export const clear = (context) => {
  context.commit("clear");
};

export const getApplicationStatus = async (context) => {
  const { data, error } = await CarerAPI.getApplicationStatus(
    authServiceInstance.getAuthenticatedUser().sub
  );
  if (!error) {
    context.commit("setApplicationStatus", data);
  }
};

export const getOnBoardDetails = async (context) => {
  const { data, error } = await CarerAPI.getOnboardStatus(
    authServiceInstance.getAuthenticatedUser().sub
  );

  if (!error) {
    context.commit("setOnBoardDetails", data);
  }
};

export const getAvailabilities = async () => {
  // const { startDate } = args;
  // const res = await cFetch(
  //   `${import.meta.env.VUE_APP_API_URL}/carer/availabilities/`,
  //   {
  //     params: {
  //       startDate,
  //     },
  //   }
  // );
  // context.commit("setAvailabilities", res);
};

export const getJobRequests = async (context, args) => {
  const { startTime, endTime } = args;

  const params = {
    startTime,
    endTime,
    carerId: authServiceInstance.getUserId(),
  };

  const { data, error } = await ClientAPI.getBookingRequests(
    authServiceInstance.getUserId(),
    params
  );

  if (!error) {
    context.commit("setBookingRequests", data);
  }
};

export const getJobs = async (context, args) => {
  const { startTime, endTime } = args;

  const params = {
    startTime,
    endTime,
  };

  const { data, error } = await ClientAPI.getBookings(
    authServiceInstance.getUserId(),
    params
  );

  if (!error) {
    context.commit("setBookings", data);
  }
};

export const getShifts = async () => {
  // const { startTime, endTime } = args;
  // const params = {
  //   startTime,
  //   endTime,
  // };
  // const res = await cFetch(
  //   `${import.meta.env.VUE_APP_API_URL}/carer/job/shift`,
  //   {
  //     params,
  //   }
  // );
  // context.commit("setShifts", res);
};

export const getDetails = async (context) => {
  const { data, error } = await CarerAPI.getProfileDetails(
    authServiceInstance.getAuthenticatedUser().sub
  );

  if (!error) {
    context.commit("setDetails", data);
  }
};

export const getJob = async (context, args) => {
  const { id } = args;
  const { data, error } = await ClientAPI.getBooking(
    authServiceInstance.getUserId(),
    id
  );
  if (!error) {
    context.commit("setBooking", data);
  } else {
    context.commit("setBooking", null);
  }
};

export const getShift = async () => {
  // const { id } = args;
  // const url = `${import.meta.env.VUE_APP_API_URL}/carer/job/shift/${id}`;
  // try {
  //   const shift = await cFetch(url);
  //   context.commit("setShift", shift);
  // } catch (err) {
  //   context.commit("setShift", null);
  //   throw err;
  // }
};
