
<v-snackbar
  :color="color"
  :timeout="timer"
  v-model="showSnackbar"
  bottom
  right
>
  <!-- <v-icon left>{{ icon }}</v-icon> -->
  {{ message }}
</v-snackbar>
