export default () => ({
  details: null,
  form: {
    eligibleToWork: true,
  },
  applicationStatus: null,

  // Welcome screen
  onBoardDetails: null,

  // Jobs
  bookingFilters: {},

  bookings: {
    items: [],
  },

  // job requests
  bookingRequestsFilters: {
    bookingDate: null,
    multiDays: null,
    showPayment: null,
    hideIncompleteBookings: null,
    serviceUser: null,
  },
  bookingRequestShowFilters: false,
  bookingRequests: {
    items: [],
  },

  // Job
  booking: null,
  mBookingCancel: false,
  mBookingQR: false,
  mBookingReview: false,

  // Shift
  shift: null,
});
