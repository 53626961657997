import Vue from "vue";
import VueIntercom from "@mathieustan/vue-intercom";
import App from "./App.vue";
import VueRouterBackButton from "vue-router-back-button";
import router from "./routes";
import store from "./store";
import vuetify from "../plugins/vuetify";

import "../prototypes";

// To reduce the size of the chunk
import(/* webpackChunkName: "qrcode" */ "vue-qrcode-reader").then(
  ({ default: VueQrcodeReader }) => {
    Vue.use(VueQrcodeReader);
  }
);

// To reduce the size of the chunk
import(/* webpackChunkName: "progressiveimage" */ "vue-progressive-image").then(
  ({ default: VueProgressiveImage }) => {
    Vue.use(VueProgressiveImage);
  }
);

Vue.config.productionTip = true;

Vue.use(VueRouterBackButton, {
  router,
  ignoreRoutesWithSameName: true,
});

if (import.meta.env.VUE_APP_NON_PROD) {
  Vue.use(VueIntercom, { appId: import.meta.env.VUE_APP_INTERCOM_APP_ID });
}

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
