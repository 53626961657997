import { IdentityAPI } from "@apiV2";
import { ActionContext } from "vuex";
import { RootState, SnackbarData } from "./state";

export const getUserInfo = async (
  context: ActionContext<RootState, RootState>
) => {
  const { data, error } = await IdentityAPI.getUserInfo();

  if (error) {
    throw new Error("Failed to fetch user info");
  }

  context.commit("setUser", data);
};

export const addSnackbar = (
  context: ActionContext<RootState, RootState>,
  snackbar: SnackbarData
) => {
  context.commit("setSnackbars", snackbar);
};
