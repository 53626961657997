
<v-snackbar light :value="needRefresh" timeout="-1" top class="mt-5" vertical>
  <span>New content available, click on reload to update.</span>

  <template v-slot:action="{ attrs }">
    <v-btn
      color="primary"
      small
      text
      v-bind="attrs"
      @click="closePromptUpdateSW()"
      class="mr-5"
      >Close</v-btn
    >
    <v-btn
      color="primary"
      small
      v-bind="attrs"
      :loading="refreshing"
      @click="updateServiceWorker()"
    >
      reload</v-btn
    >
  </template>
</v-snackbar>
