<template>
  <div class="home-navbar">
    <v-container style="padding: 0px">
      <div class="d-flex px-5">
        <router-link to="/">
          <div class="mt-3 d-flex">
            <img style="max-width: 300px" src="/assets/public/ucc-logo.png" />
          </div>
          <div
            style="
              font-size: 24px;
              color: #152939;
              font-weight: bold;
              padding-left: 0px;
              letter-spacing: -50;
              font-family: Arial;
              min-width: 375px;
            "
          >
            Get
            <span style="color: #005eb8">Connected</span> with
            <span style="color: #005eb8">Caregivers</span>
          </div>
        </router-link>
        <div class="hidden-md-and-down">
          <div class="d-flex align-end" style="height: 100%">
            <v-row>
              <v-col
                cols="auto"
                xs="12"
                sm="12"
                md="12"
                lg="12"
                xl="12"
                class="social-media-icon text-right"
              >
                <ul style="display: flex; float: right; list-style-type: none">
                  <li>
                    <a
                      href="https://www.facebook.com/urbancarecommunity"
                      target="_blank"
                    >
                      <v-img
                        src="../../assets/public/facebookx24.png"
                        width="36"
                      ></v-img>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://twitter.com/ucarecommunity1"
                      target="_blank"
                    >
                      <v-img
                        src="/assets/public/twitterx24.png"
                        width="36"
                      ></v-img>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://uk.linkedin.com/in/urbancare-community-31561b142"
                      target="_blank"
                    >
                      <v-img
                        src="/assets/public/linkedinx24.png"
                        width="36"
                      ></v-img>
                    </a>
                  </li>
                  <li>
                    <v-btn text to="/faq">FAQ</v-btn>|<v-btn
                      text
                      to="/ucc-login"
                      >Login</v-btn
                    >
                  </li>
                </ul>
              </v-col>
              <v-col
                cols="12"
                xs="12"
                sm="12"
                md="12"
                lg="12"
                xl="12"
                class="text-right pb-0"
              >
                <v-btn text to="/">Home</v-btn>|<v-btn text to="/about"
                  >About Us</v-btn
                >|<v-btn text to="/care-providers">Care Provider</v-btn>|<v-btn
                  text
                  to="/care-workers-and-nurses"
                  >Care Workers & Nurses</v-btn
                >|<v-btn text to="/training">Training</v-btn>
              </v-col>
            </v-row>
          </div>
        </div>
        <div class="hidden-lg-and-up">
          <v-menu
            offset-y
            offset-x
            origin="top right"
            transition="scale-transition"
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                large
                v-bind="attrs"
                v-on="on"
                style="position: absolute; top: 10px; right: 10px; padding: 0"
              >
                <v-icon>mdi-menu</v-icon>
              </v-btn>
            </template>
            <v-list>
              <div v-for="(item, index) in items" :key="index">
                <v-divider v-if="item.divider" class="my-2" />
                <v-list-item
                  :to="item.href"
                  exact-active-class="route-active"
                  v-else
                >
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
              </div>
            </v-list>
          </v-menu>
        </div>
      </div>
    </v-container>
  </div>
</template>
<script>
export default {
  name: "NewNavBar",
  data: () => ({
    navDrawer: false,
    profiles: [
      {
        color: "mx-1 indigo  darken-4--text",
        icon: "mdi-facebook",
        link: "https://www.facebook.com/",
      },
      {
        color: "mx-1 indigo  darken-4--text",
        icon: "mdi-twitter",
        link: "https://www.twitter.com/",
      },
      {
        color: "mx-1 indigo  darken-4--text--text",
        icon: "mdi-linkedin",
        link: "https://www.linkedin.com/",
      },
    ],
    items: [
      { title: "Login", href: "/ucc-login" },
      { title: "FAQ", href: "/faq" },
      { divider: true },
      { title: "Home", href: "/" },
      { title: "About Us", href: "/about" },
      { title: "Care providers", href: "/care-providers" },
      { title: "Care Workers & Nurses", href: "/care-workers-and-nurses" },
      { title: "Training", href: "/training" },
    ],
  }),
  methods: {
    goTo() {
      this.$router.push("/");
    },
  },
};
</script>
<style lang="scss">
.route-active {
  background-color: #e6eff8;
}
.home-navbar {
  font-family: Roboto;
  font-size: 28px;
  background-color: #fff;
  height: 210px !important;
  a {
    text-decoration: none;
  }
  .social-media-icon {
    .v-btn {
      font-family: Roboto;
      text-decoration: none;
      text-transform: none;
      margin: 0px !important;
    }
    .v-btn:hover {
      font-family: Roboto;
      text-decoration: none;
      text-transform: none;
      margin: 0px !important;
    }
    ul li {
      padding: 0px 5px;
    }
  }
  .v-btn {
    font-family: Roboto;
    text-decoration: none;
    text-transform: none;
    font-size: 18px !important;
    letter-spacing: normal;
  }
  .logo-bg {
    display: flex;
    align-items: center;
    padding-right: 10px;
    padding-top: 120px;
    color: #fff;
    font-size: 24px;
    width: 400px;
  }
}
</style>
