import {
  BookingForm,
  BookingFormShift,
  ICarers,
  JobPostForm,
} from "@urbancarecommunity/commons/@types";

export interface ClientBookingFormCareWorker {
  id: string;
  shift: string;
  role: string;
}

export interface ClientBookingFormShift {
  carers: ICarers[];
  _id: string;
  name: string;
  startTime?: string;
  endTime?: string;
  address?: string;
  taskList?: string;
  careWorkers: ClientBookingFormCareWorker[];
}

export interface ClientBookingForm {
  dates: string[];
  serviceUser: string | null;
  shifts: ClientBookingFormShift[];
  currentShift: string | null;
  id: string;
}
export interface ClientJobPostForm {
  serviceUserId: string | null;
  dates: string[];
  title: string;
  description: string;
  isPubliclyVisible: boolean;
  serviceUser: string | null;
  currentShift: string | null;
  shifts: BookingFormShift[];
  id: string;
}

export interface ClientDetails {
  address: string;
  city: string;
  landline: string;
  mobile: string;
  name: string;
  postCode: string;
  approved: boolean;
}

export interface ClientSearchFilter {
  firstname: string;
  lastname: string;
  location: string;
  role: string;
  trainings: string[];
  rating: string;
}

export interface ClientApplicationStatusDetails {
  approved: boolean;
  disabled: boolean;
}

export interface ClientState {
  [x: string]: {
    serviceUser: string | null;
    dates: string[];
    shifts: BookingFormShift[];
    currentShift: string | null;
    offSet: string | null;
  };
  bookingForm: BookingForm;
  searchFilter: ClientSearchFilter;
  bookingFilters: {};
  details: ClientDetails;
  applicationStatus: ClientApplicationStatusDetails;
  jobPost: JobPostForm;
}

export const getBookingFormInitialState = (): BookingForm => {
  return {
    dates: [],
    serviceUser: null,
    shifts: [],
    currentShift: null,
  };
};

export const getJobPostFormInitialState = (): JobPostForm => {
  return {
    dates: [],
    serviceUser: null,
    shifts: [],
    currentShift: null,
    title: "",
    description: "",
    offSet: "",
    isPubliclyVisible: false,
  };
};

const clientState = (): ClientState => {
  return {
    searchFilter: {
      firstname: "",
      lastname: "",
      location: "",
      role: "",
      trainings: [],
      rating: "",
    },

    bookingForm: getBookingFormInitialState(),
    bookingFilters: {},
    details: {
      address: "",
      city: "",
      landline: "",
      mobile: "",
      name: "",
      postCode: "",
      approved: false,
    },
    applicationStatus: {
      approved: false,
      disabled: false,
    },
    jobPost: getJobPostFormInitialState(),
  };
};

export default clientState;
