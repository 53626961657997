import { IUserType } from "@urbancarecommunity/commons/@types";
import { CarerRole } from "@urbancarecommunity/commons/constants";
import { NavigationGuard } from "vue-router";
import { authServiceInstance } from "..";

export const CarerSubmittedGuard: NavigationGuard = (to, from, next) => {
  if (!authServiceInstance.isAuthenticatedAsCarer()) {
    return next("/auth/login");
  }

  const role = authServiceInstance.getAuthRole();
  if (role === CarerRole.ApprovedCarer) {
    return next("/carer/portal");
  }
  if (role === CarerRole.UnapprovedCarer) {
    return next("/carer/application");
  }
  if (role !== CarerRole.SubmittedCarer) {
    return next("/");
  }

  next();
};
export const CarerApplicationGuard: NavigationGuard = (to, from, next) => {
  if (!authServiceInstance.isAuthenticatedAsCarer()) {
    return next("/auth/login");
  }

  const role = authServiceInstance.getAuthRole();
  if (role === CarerRole.SubmittedCarer) {
    return next("/carer/application/submitted");
  }
  if (role === CarerRole.ApprovedCarer) {
    return next("/carer/portal");
  }
  if (role !== CarerRole.UnapprovedCarer) {
    return next("/carer");
  }

  next();
};

export const CarerPortalGuard: NavigationGuard = (to, from, next) => {
  if (!authServiceInstance.isAuthenticatedAsCarer()) {
    return next("/auth/login");
  }

  const role = authServiceInstance.getAuthRole();
  if (role === CarerRole.UnapprovedCarer) {
    return next("/carer/application");
  }
  if (role === CarerRole.SubmittedCarer) {
    return next("/carer/application/submitted");
  }
  if (role === CarerRole.DisabledCarer) {
    return next("/carer/disabled");
  }
  if (role !== CarerRole.ApprovedCarer) {
    return next("/carer");
  }

  next();
};

export const CarerDisabledGuard: NavigationGuard = (to, from, next) => {
  if (!authServiceInstance.isAuthenticatedAsCarer()) {
    return next("/auth/login");
  }

  const role = authServiceInstance.getAuthRole();
  if (role === CarerRole.UnapprovedCarer) {
    return next("/carer/application");
  }
  if (role === CarerRole.SubmittedCarer) {
    return next("/carer/application/submitted");
  }
  if (role === CarerRole.ApprovedCarer) {
    return next("/carer");
  }

  next();
};

export const CarerGuard: NavigationGuard = (to, from, next) => {
  if (!authServiceInstance.isAuthenticatedAsCarer()) {
    return next("/auth/login");
  }

  const user = authServiceInstance.getAuthenticatedUser();
  if (user?.userType !== IUserType.CARER) {
    return next("/auth/login");
  }

  next();
};
