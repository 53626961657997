
<v-app :style="`background: ${appBackground}`">
  <ReloadPrompt />
  <PublicNavBar v-if="!isAuthenticated" />
  <GlobalSnackbarVue ref="snackbar" />
  <v-main>
    <v-container :fluid="$vuetify.breakpoint.mdAndDown">
      <router-view />
    </v-container>
  </v-main>
  <PublicFooter v-if="!isAuthenticated" />
  <OnlineStatus />
  <v-dialog
    transition="dialog-top-transition"
    max-width="600"
    persistent
    :value="mStagingCredentials"
  >
    <v-card>
      <v-toolbar color="primary" dark class="px-3 title"
        >Verification</v-toolbar
      >
      <v-layout column class="pa-8 pb-0">
        <label class="title pb-3">UCC Staging Password</label>
        <v-text-field
          outlined
          class="my-3"
          type="password"
          label="Enter Password"
          v-model="stagingCredentials"
          :error-messages="stagingCredentialsError"
        />
      </v-layout>
      <v-card-actions class="justify-end px-6 pb-5 pt-0">
        <v-btn
          :disabled="stagingCredentials.length === 0"
          color="primary"
          @click="verifyStagingCredentials"
          >Verify</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</v-app>
