import * as actions from "./actions";
import * as mutations from "./mutations";
import * as getters from "./getters";
import state from "./state";

// Mutation And Action for JobPost
// import * as jobPostMutations from "./jobPostMutations";
// import * as jobPostActions from "./jobPostActions";
// import * as jobPostGetter from "./jobPostGetter";

export default {
  namespaced: true,
  state,
  actions: {
    ...actions,
    // ...jobPostActions,
  },
  mutations: {
    ...mutations,
    // ...jobPostMutations,
  },
  getters: {
    ...getters,
    // ...jobPostGetter,
  },
};
