<script>
import useRegisterSW from "../../mixins/useRegisterSw";
import ms from "ms";
const intervalMS = ms("1m");

export default {
  name: "reload-prompt",
  mixins: [useRegisterSW],
  methods: {
    handleSWManualUpdates(swRegistration) {
      swRegistration &&
        setInterval(() => {
          swRegistration.update();
        }, intervalMS);
    },
  },
};
</script>

<template>
  <v-snackbar light :value="needRefresh" timeout="-1" top class="mt-5" vertical>
    <span>New content available, click on reload to update.</span>

    <template v-slot:action="{ attrs }">
      <v-btn
        color="primary"
        small
        text
        v-bind="attrs"
        @click="closePromptUpdateSW()"
        class="mr-5"
        >Close</v-btn
      >
      <v-btn
        color="primary"
        small
        v-bind="attrs"
        :loading="refreshing"
        @click="updateServiceWorker()"
      >
        reload</v-btn
      >
    </template>
  </v-snackbar>
</template>

<style>
.pwa-toast {
  position: fixed;
  right: 0;
  bottom: 0;
  margin: 16px;
  padding: 12px;
  border: 1px solid #8885;
  border-radius: 4px;
  z-index: 1;
  text-align: left;
  box-shadow: 3px 4px 5px 0 #8885;
}
.pwa-toast .message {
  margin-bottom: 8px;
}
.pwa-toast button {
  border: 1px solid #8885;
  outline: none;
  margin-right: 5px;
  border-radius: 2px;
  padding: 3px 10px;
}
</style>
