import Vue from "vue";
import {
  ClientDetails,
  ClientSearchFilter,
  ClientState,
  getBookingFormInitialState,
  ClientApplicationStatusDetails,
  ClientBookingFormShift,
  ClientBookingForm,
  getJobPostFormInitialState,
} from "./state";
import mongoose from "mongoose";

import { ICarers } from "@urbancarecommunity/commons/@types";
/* eslint-disable no-param-reassign */

export const setOnBoardDetails = (state: ClientState, nextState: unknown) => {
  Vue.set(state, "onBoardDetails", nextState);
};

export const setSearchFilters = (
  state: ClientState,
  nextState: ClientSearchFilter
) => {
  state.searchFilter = nextState;
};

export const clearBookingForm = (state: ClientState) => {
  const existingForm = state.bookingForm;
  state.bookingForm = {
    ...getBookingFormInitialState(),
    serviceUser: existingForm.serviceUser,
    dates: state.bookingForm.dates.splice(0, state.bookingForm.dates.length),
  };
};

export const setDetails = (state: ClientState, nextState: ClientDetails) => {
  state.details = nextState;
};

export const setApplicationStatus = (
  state: ClientState,
  nextState: ClientApplicationStatusDetails
) => {
  state.applicationStatus = nextState;
};

export const setBookingShifts = (
  state: ClientState,
  nextState: ClientBookingFormShift
) => {
  state.bookingForm.shifts = state.bookingForm.shifts.map((shift) => {
    if (shift._id !== nextState._id) {
      return shift;
    }

    return nextState;
  });
};

export const setBookingFormShiftField = (
  state: ClientState,
  nextState: Partial<ClientBookingFormShift>
) => {
  state.bookingForm.shifts = state.bookingForm.shifts.map((shift) => {
    if (shift._id !== nextState._id) {
      return shift;
    }

    return { ...shift, ...nextState };
  });
};

export const addShiftToBookingForm = (state: ClientState) => {
  state.bookingForm.shifts.push({
    _id: new mongoose.Types.ObjectId().toString(),
    name: "",
    startTime: undefined,
    endTime: undefined,
    address: undefined,
    taskList: undefined,
    // careWorkers: [],
    carers: [],
  });
};

export const modifyBookingFormDates = (
  state: ClientState,
  nextState: string[]
) => {
  state.bookingForm.dates = nextState;
  if (nextState.length < 1) {
    state.bookingForm.shifts = [];
  }
};

export const setJobPost = (state: ClientState, nextState: string[]) => {
  state.jobPost = nextState;
};

export const duplicateShiftInBookingForm = (
  state: ClientState,
  nextState: ClientBookingFormShift
) => {
  const newShift = {
    ...nextState,
    startTime: undefined,
    endTime: undefined,
    _id: new mongoose.Types.ObjectId().toString(),
  };

  state.bookingForm.shifts.push(newShift);
};

export const setCurrentShift = (state: ClientState, nextState: string) => {
  state.bookingForm.currentShift = nextState;
};

export const addCarerToShift = (state: ClientState, nextState: ICarers) => {
  const carer: ICarers = {
    ...nextState,
  };
  const currentShift: any = state.bookingForm.currentShift;
  const existingCarers = state.bookingForm.shifts[currentShift].carers;
  const doesCarerExist = existingCarers?.some(
    ({ _id }: { _id: mongoose.ObjectId }) => _id === carer._id
  );
  if (!doesCarerExist && existingCarers) {
    state.bookingForm.shifts[currentShift].carers = [...existingCarers, carer];
  } else {
    state.bookingForm.shifts[currentShift].carers = [carer];
  }
};

export const updateCurrentShift = (
  state: ClientState,
  nextState: ClientBookingFormShift
) => {
  state.bookingForm.shifts = state.bookingForm.shifts.map((shift) => {
    if (shift._id !== nextState._id) {
      return shift;
    }

    return { ...shift, ...nextState };
  });
};

export const setCarerRoleInShift = (
  state: ClientState,
  nextState: { shiftIndex: number; carerIndex: number; role: string }
) => {
  const { shiftIndex, carerIndex, role } = nextState;

  Vue.set(
    state.bookingForm.shifts[shiftIndex].carers[carerIndex],
    "role",
    role
  );
};

export const removeCarerFromShift = (
  state: ClientState,
  nextState: { shiftIndex: number; carerIndex: number }
) => {
  state.bookingForm.shifts[nextState.shiftIndex].carers.splice(
    nextState.carerIndex,
    1
  );
};

export const removeBookDate = (
  state: ClientState,
  nextState: { dateIndex: number }
) => {
  state.bookingForm.shifts = state.bookingForm.dates.splice(
    nextState.dateIndex,
    1
  );
};

export const deleteShift = (
  state: ClientState,
  nextState: { shiftIndex: number }
) => {
  state.bookingForm.shifts.splice(nextState.shiftIndex, 1);
};

export const modifyBookingShift = (
  state: ClientState,
  nextState: { index: number; key: string; value: string }
) => {
  const { index, key, value } = nextState;

  Vue.set(state.bookingForm.shifts[index], key, value);
};

export const removeCarerFromBooking = (
  state: ClientState,
  nextState: { shiftIndex: number; carerIndex: number }
) => {
  const { shiftIndex, carerIndex } = nextState;

  state.bookingForm.shifts[shiftIndex].carers.splice(carerIndex, 1);
};

export const setBooking = (
  state: ClientState,
  nextState: ClientBookingForm
) => {
  state.bookingForm = nextState;
};

export const modifyJobPostDates = (state: ClientState, nextState: string[]) => {
  state.jobPost.dates = nextState;
};

export const modifyJobPost = (
  state: ClientState,
  nextState: { key: string; value: string }
) => {
  const { key, value } = nextState;
  Vue.set(state.jobPost, key, value);
};

export const addShiftToJobPost = (state: ClientState) => {
  state.jobPost.shifts.push({
    _id: new mongoose.Types.ObjectId().toString(),
    name: "",
    startTime: undefined,
    endTime: undefined,
    address: undefined,
    taskList: undefined,
    carers: [],
  });
};

export const modifyJobPostShift = (
  state: ClientState,
  nextState: { index: number; key: string; value: string }
) => {
  const { index, key, value } = nextState;

  Vue.set(state.jobPost.shifts[index], key, value);
};

export const deleteJobPostShift = (
  state: ClientState,
  nextState: { index: number }
) => {
  state.jobPost.shifts.splice(nextState.index, 1);
};

export const clearJobPost = (state: ClientState) => {
  const existingForm = state.jobPost;
  state.jobPost = {
    ...getJobPostFormInitialState(),
    serviceUser: existingForm.serviceUser,
  };
};

export const setShiftRequirement = (
  state: ClientState,
  nextState: {
    shiftIndex: number;
    requirementIndex: number;
    key: string;
    value: string;
  }
) => {
  const { shiftIndex, requirementIndex = 1, key, value } = nextState;
  Vue.set(
    state.jobPost.shifts[shiftIndex].requirements[requirementIndex],
    key,
    value
  );
};
