import moment from "moment";
import router from "../../routes";
import { isSomething } from "@helpers/any";
import { authServiceInstance } from "@auth/index";
import { ClientAPI } from "@apiV2";

export const bookAgain = async (context, existingBooking) => {
  router.push("/client/portal/book");
  const { template } = existingBooking;
  const serviceUser = template?.serviceUser._id;
  const { res: tasks, err } = await ClientAPI.getTaskList(
    authServiceInstance.getUserId(),
    serviceUser
  );

  if (!err) {
    const shifts = existingBooking.shifts.map((shift) => {
      const taskIds = shift.tasks.map(({ _id }) => _id);
      const { _id: taskList, tasks: existingTasks } = tasks.find(({ tasks }) =>
        tasks.some(({ _id }) => taskIds.includes(_id))
      );
      return {
        id: shift._id,
        taskList,
        tasks: existingTasks,
        name: shift.name,
        carers: shift.carers,
        address: shift.address._id,
        startTime: moment(shift.startTime).format("HH:mm"),
        endTime: moment(shift.endTime).format("HH:mm"),
      };
    });

    const clonedBooking = {
      ...template,
      shifts,
      dates: [],
      serviceUser,
    };
    context.commit("clearBookingForm");
    context.commit("setBook", clonedBooking);
  }
};

export const getApplicationStatus = async (context) => {
  const { data, error } = await ClientAPI.getApplicationStatus(
    authServiceInstance.getAuthenticatedUser().sub
  );

  if (!error) {
    context.commit("setApplicationStatus", data);
  }
};

export const getDetails = async (context) => {
  const { data, error } = await ClientAPI.getClientProfile(
    authServiceInstance.getUserId()
  );

  if (!error) {
    context.commit("setDetails", data);
  }
};

export const getOnBoardDetails = async (context) => {
  const { data, error } = await ClientAPI.getOnBoardDetails(
    authServiceInstance.getUserId()
  );
  if (!error) {
    context.commit("setOnBoardDetails", data);
  }
};
export const getBookings = async (context, args) => {
  const { startTime, endTime, serviceUserId } = args;

  const params = {
    startTime,
    endTime,
    serviceUserId: serviceUserId,
  };

  if (!isSomething(serviceUserId)) {
    delete params.serviceUserId;
  }

  const { data, error } = await ClientAPI.getBookings(
    authServiceInstance.getUserId(),
    params
  );

  if (!error) {
    context.commit("setBookings", data);
  }
};

export const getShifts = async () => {
  // const { startTime, endTime, serviceUserId } = args;
  // const params = {
  //   startTime,
  //   endTime,
  //   serviceUserId: serviceUserId,
  // };
  // if (!isSomething(serviceUserId)) {
  //   delete params.serviceUserId;
  // }
  // const res = await cFetch(
  //   `${import.meta.env.VUE_APP_API_URL}/client/booking/shift`,
  //   {
  //     params,
  //   }
  // );
  // context.commit("setShifts", res);
};

export const getBooking = async () => {
  // let id = null;
  // if (args) {
  //   ({ id } = args);
  // }
  // const { booking } = context.state;
  // if (!id && booking) {
  //   id = booking._id;
  // }
  // const url = `${import.meta.env.VUE_APP_API_URL}/client/booking/${id}`;
  // const res = await cFetch(url);
  // context.commit("setBooking", res);
};

export const getShift = async () => {
  // let id = null;
  // if (args) {
  //   ({ id } = args);
  // }
  // const { shift } = context.state;
  // if (!id && shift) {
  //   id = shift._id;
  // }
  // const url = `${import.meta.env.VUE_APP_API_URL}/client/booking/shift/${id}`;
  // const res = await cFetch(url);
  // context.commit("setShift", res);
};

export const cancelBooking = async () => {
  // await cFetch(`${import.meta.env.VUE_APP_API_URL}/client/booking`, {
  //   method: "DELETE",
  //   params: {
  //     id: context.state.booking._id,
  //   },
  // });
};
