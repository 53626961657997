import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import CARER_MODULE from "./carer_module";
import CLIENT_MODULE from "./client_module";
import ROOT from "./root";
import { isSomething } from "../../helpers";

/**
 * The version number for the app. Should be updated
 * every time the app is updated for the client
 */
const version = "1.0.0";

const { state, actions, mutations, getters } = ROOT;

Vue.use(Vuex);

export const INITIAL_STATE = {
  applicationStatus: null,
  user: null,
  token: null,
};

// const prevState = localStorage.getItem('vuex');

// console.log(JSON.parse(prevState));

const getCrucialState = (prevState) => {
  if (!isSomething(prevState)) {
    return {};
  }

  const prevStateJ = JSON.parse(prevState);

  const { carer, client, user } = prevStateJ;

  const caApplicationStatus = carer.applicationStatus;
  const clApplicationStatus = client.applicationStatus;

  return {
    user,
    carer: {
      applicationStatus: caApplicationStatus,
    },
    client: {
      applicationStatus: clApplicationStatus,
    },
  };
};

const store = new Vuex.Store({
  state,
  modules: {
    carer: CARER_MODULE,
    client: CLIENT_MODULE,
  },
  actions,
  mutations,
  getters,
  plugins: [
    // createPersistedState(),
    createPersistedState({
      paths: ["client.bookingForm"],
      setState: (key, state) => {
        localStorage.setItem(key, JSON.stringify(state));
      },
      getState: () => {
        try {
          // Get the state from localstorage
          const prevState = localStorage.getItem("vuex");
          const storedVersion = localStorage.getItem("version");
          console.info(
            `%cApplication version: ${storedVersion}`,
            "background: #222; color: #bada55"
          );
          if (storedVersion !== version) {
            console.warn(
              `%cApplication version mismatach:
            stored is: ${storedVersion} and current is: ${version}`,
              "color: #bada55"
            );
            const newState = getCrucialState(prevState);
            localStorage.setItem("version", version);
            return newState;
          }
          return JSON.parse(prevState);
        } catch (err) {
          console.error(err);
          localStorage.setItem("version", version);
          return {};
        }
      },
    }),
  ],
});

export default store;
