<template>
  <div>
    <v-snackbar
      :value="appOnline"
      :timeout="6000"
      color="secondary"
      bottom
      right
    >
      You're online at the moment.
    </v-snackbar>
    <v-snackbar
      :value="appOnline === false"
      color="secondary"
      :timeout="6000"
      bottom
      right
    >
      You're offline at the moment.
    </v-snackbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      appOnline: null,
    };
  },
  mounted() {
    window.addEventListener("online", this.handleConnection);
    window.addEventListener("offline", this.handleConnection);
  },
  methods: {
    handleConnection() {
      this.appOnline = navigator.onLine;
    },
  },
};
</script>
