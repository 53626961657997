import { ClientState } from "./state";

export const bookingFormShifts = (state: ClientState) => {
  const { bookingForm } = state;

  if (!bookingForm.shifts) {
    return [];
  }

  return bookingForm.shifts;
};

export const bookShifts = (state: ClientState) => {
  const { bookingForm } = state;

  if (!bookingForm.shifts) {
    return [];
  } else {
    return bookingForm.shifts;
  }
};

export const bookServiceUser = (state: ClientState, getters: any) => {
  const { bookingForm } = state;
  if (getters.isClientInd) {
    return getters.serviceUser._id;
  }
  return bookingForm.serviceUser;
};

export const getBookingFormShift = (state: ClientState) => (id: string) => {
  const { bookingForm } = state;
  return bookingForm.shifts.find((s) => s._id === id);
};

export const jobPostServiceUser = (state: ClientState, getters: any) => {
  const { jobPost } = state;
  if (getters.isClientInd) {
    return getters.serviceUser?._id;
  }

  return jobPost.serviceUser;
};

export const jobPostShifts = (state: ClientState) => {
  const { jobPost } = state;
  if (!jobPost.shifts) {
    return [];
  }
  return jobPost.shifts;
};
