<template>
  <v-app :style="`background: ${appBackground}`">
    <ReloadPrompt />
    <PublicNavBar v-if="!isAuthenticated" />
    <GlobalSnackbarVue ref="snackbar" />
    <v-main>
      <v-container :fluid="$vuetify.breakpoint.mdAndDown">
        <router-view />
      </v-container>
    </v-main>
    <PublicFooter v-if="!isAuthenticated" />
    <OnlineStatus />
    <v-dialog
      transition="dialog-top-transition"
      max-width="600"
      persistent
      :value="mStagingCredentials"
    >
      <v-card>
        <v-toolbar color="primary" dark class="px-3 title"
          >Verification</v-toolbar
        >
        <v-layout column class="pa-8 pb-0">
          <label class="title pb-3">UCC Staging Password</label>
          <v-text-field
            outlined
            class="my-3"
            type="password"
            label="Enter Password"
            v-model="stagingCredentials"
            :error-messages="stagingCredentialsError"
          />
        </v-layout>
        <v-card-actions class="justify-end px-6 pb-5 pt-0">
          <v-btn
            :disabled="stagingCredentials.length === 0"
            color="primary"
            @click="verifyStagingCredentials"
            >Verify</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import PublicNavBar from "@components/navbar/public.vue";
import PublicFooter from "@components/footer/PublicFooter.vue";
import { authServiceInstance } from "@auth/index";
import ReloadPrompt from "@components/shared/ReloadPrompt.vue";
import OnlineStatus from "@components/shared/OnlineStatus.vue";
import useRegisterSW from "../mixins/useRegisterSw";
import { PushAPI } from "@apiV2";
import GlobalSnackbarVue from "@components/GlobalSnackbar/GlobalSnackbar.vue";

export default {
  name: "UCC",
  components: {
    PublicNavBar,
    PublicFooter,
    ReloadPrompt,
    OnlineStatus,
    GlobalSnackbarVue,
  },
  data() {
    return {
      update: false,
      refreshing: false,
      stagingCredentials: "",
      stagingCredentialsError: "",
      mStagingCredentials: false,
      intercom: {
        userId: null,
        name: null,
        email: null,
      },
      isAuthenticated: false,
    };
  },
  mixins: [useRegisterSW],
  computed: {
    appBackground() {
      if (this.isAuthenticated) {
        return this.theme.background;
      }
      return "white";
    },
    theme() {
      return this.$vuetify.theme.currentTheme;
    },
    ...mapState(["mUpdateSnack", "user"]),
    publicNavBarVisibile() {
      return !authServiceInstance.isAuthenticated();
    },
  },
  watch: {
    "intercom.email": function (email) {
      this.$intercom.update({ email });
    },
    $route: function () {
      this.updatedIsAuthenticated();
    },
  },
  async beforeCreate() {
    const darkTheme = this.$store.state.darkTheme;

    if (darkTheme) {
      this.$vuetify.theme.dark = true;
    } else {
      this.$vuetify.theme.dark = false;
    }
  },
  beforeMount() {
    this.stagingCheck();
    this.updatedIsAuthenticated();
  },
  mounted() {
    this.addCookieBot();
    this.setApplicationVersion();

    this.$root.snackbar = this.$refs.snackbar;
  },
  created() {
    // this.$intercom.shutdown();
    // this.$intercom.once("ready", this.bootIntercom);
  },
  methods: {
    async handleLogout() {
      const subscription =
        await this.swRegistration.pushManager.getSubscription();

      await PushAPI.unsubscribeFromPush(
        authServiceInstance.getUserId(),
        subscription
      );

      console.info("%c[Web Push] User is unsubscribed.", "color: #ff8000");
    },
    updatedIsAuthenticated() {
      this.isAuthenticated = authServiceInstance.isAuthenticated();
    },
    bootIntercom() {
      // this.$intercom.boot();
      // this.$intercom.show();
      // this.$intercom.hide();
    },
    setApplicationVersion() {
      const version = localStorage.getItem("version");

      this.$store.commit("setApplicationVersion", version);
    },
    addCookieBot() {
      if (import.meta.env.DEV) {
        return;
      }

      const cookieBot = document.createElement("script");
      cookieBot.setAttribute("src", "https://consent.cookiebot.com/uc.js");
      cookieBot.setAttribute("type", "text/javascript");
      cookieBot.setAttribute(
        "data-cbid",
        "11cba7c3-70ce-4d75-9204-f38a28da5834"
      );
      cookieBot.setAttribute("data-blockingmode", "auto");
      cookieBot.id = "Cookiebot";

      const head = document.getElementsByTagName("head")[0];
      head.appendChild(cookieBot);
    },
    stagingCheck() {
      const stagingVerification =
        localStorage.getItem("stagingVerification") || false;
      this.mStagingCredentials =
        import.meta.env.VUE_APP_NON_PROD && !stagingVerification;
    },
    verifyStagingCredentials() {
      const isValid =
        this.stagingCredentials === import.meta.env.VUE_APP_STAGING_PASSWORD;
      if (isValid) {
        localStorage.setItem("stagingVerification", isValid);
        this.mStagingCredentials = false;
      } else {
        this.stagingCredentialsError = "Wrong Password, Please try again";
      }
    },
  },
};
</script>

<style lang="scss">
@import "../scss/index.scss";

h4 {
  font-weight: 200;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.main-heading {
  font-weight: bold;
  letter-spacing: 0.5;
  line-height: 26px;
  font-size: 24px;
  padding-top: 30px;
  padding-bottom: 30px;
}
.heading-medium {
  font-weight: 500;
  letter-spacing: 0.5;
  line-height: 26px;
  font-size: 20px;
}
.sub-heading-bold {
  font-weight: bold;
  letter-spacing: 0.5;
  line-height: 24px;
  font-size: 18px;
}
.field-name {
  font-weight: bold;
  letter-spacing: 0.5;
  line-height: 24px;
  font-size: 16px;
}
.field-value {
  font-weight: normal;
  letter-spacing: 0.5;
  line-height: 21px;
  font-size: 16px;
}
.field-value-1 {
  font-weight: normal;
  letter-spacing: 0.5;
  line-height: 19px;
  font-size: 14px;
  color: #000;
}
.border-style {
  border: Solid 2px #b3daff;
  border-radius: 5px;
}

.main-heading {
  font-weight: bold;
  letter-spacing: 0.5;
  line-height: 26px;
  font-size: 24px;
  padding-top: 30px;
  padding-bottom: 30px;
}
.heading-medium {
  font-weight: 500;
  letter-spacing: 0.5;
  line-height: 26px;
  font-size: 20px;
}
.sub-heading-bold {
  font-weight: bold;
  letter-spacing: 0.5;
  line-height: 24px;
  font-size: 18px;
}
.field-name {
  font-weight: bold !important;
  letter-spacing: 0.5;
  line-height: 24px;
  color: #000 !important;
  font-size: 16px !important;
}
.field-value {
  font-weight: normal;
  letter-spacing: 0.5;
  line-height: 21px;
  font-size: 16px;
}
.field-value-1 {
  font-weight: normal;
  letter-spacing: 0.5;
  line-height: 19px;
  font-size: 14px;
  color: #000;
}
.border-style {
  border: Solid 2px #b3daff;
  border-radius: 5px;
}
.text-truncate-title {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.SUDetailsWidth {
  width: 480px;
}
.shiftCardHeight {
  min-height: 120px;
}
@media (min-width: 300px) and (max-width: 900px) {
  .SUDetailsWidth {
    width: 320px;
  }
  .card-headline {
    font-size: 1.2rem !important;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: normal !important;
    font-family: "Roboto", sans-serif !important;
  }
}

.public-page {
  // height: 100vh;
  &:nth-child(2) {
    @media only screen and (max-width: 960px) {
      margin-top: 5rem;
    }
  }
  &.full-height {
    @media only screen and (min-width: 960px) {
      height: 100vh;
    }
  }
  position: relative;
  .public-page-title {
    font-family: "Clan W01 Bold";
    color: black;
    font-size: 3.5rem;
    @media only screen and (max-width: 960px) {
      margin-top: 2rem;
      font-size: 2rem;
    }
  }
  .public-page-subheader {
    font-family: "Clan W01 News";
    color: black;
    font-size: 2rem;
    @media only screen and (max-width: 960px) {
      font-size: 1.7rem;
    }
    .public-page-subheader {
      // font-family: "Clan W01 News";
      color: black;
      font-size: 2rem;
      @media only screen and (max-width: 960px) {
        font-size: 1.7rem;
      }
    }
    .public-page-subheader-2 {
      // font-family: "Clan W01 News";
      color: black;
      font-size: 1.4rem;
    }
    .public-page-body {
      // font-family: "Clan W01 News";
      white-space: pre-line;
      color: #666666;
      font-size: 1.1rem;
      // font-size: 18px;
      // margin-bottom: 1.8rem !important;
      text-align: justify;
      line-height: 22.5pt;
      margin-top: 1.7rem;
      @media only screen and (max-width: 960px) {
        width: auto;
      }
    }
  }
  .public-page-art {
    position: relative;
    @media only screen and (max-width: 960px) {
      justify-content: center;
    }
    .public-page-image {
      max-width: 1250px;
      position: absolute;
      width: 120vw;
      left: -12rem;
      margin-top: 5rem;
      @media only screen and (max-width: 960px) {
        position: static;
      }
    }
  }
  .public-page-side-art {
    img {
      height: 35rem;
      @media only screen and (max-width: 960px) {
        height: auto;
        width: 90vw;
      }
    }
  }
}

// Public

.public {
  font-family: "Roboto";
  margin-top: -12px;
  a {
    text-decoration: none;
  }

  .v-btn {
    font-family: Roboto;
  }

  h1 {
    font-size: 48px;
    font-weight: 700;
    letter-spacing: 0.25px;
    line-height: 56px;
    padding-bottom: 16px;
  }
  h2 {
    font-size: 32px;
    letter-spacing: 0.25px;
    line-height: 40px;
    font-weight: 500;
    padding-bottom: 24px;
  }
  .h2-small {
    font-size: 28px;
    letter-spacing: 0.25px;
    line-height: 36px;
    font-weight: 400;
  }
  h3 {
    font-size: 24px;
    letter-spacing: 0.25px;
    line-height: 36px;
    font-weight: 500;
    &.h3-color {
      color: #005eb8;
    }
    a {
      color: blue !important;
    }
  }
  h4 {
    font-size: 24px;
    letter-spacing: 0.25px;
    line-height: 36px;
    font-weight: 500;
    padding: 18px 0px;
  }
  h5 {
    font-size: 24px;
    letter-spacing: 0.25px;
    line-height: 29px;
    font-weight: 500;
    padding: 18px 0px;
  }
  h6 {
    font-size: 16px;
    letter-spacing: 0.25px;
    line-height: 24px;
    font-weight: 500;
  }
  p {
    font-size: 19px;
    line-height: 28px;
    letter-spacing: 0px;
    font-family: "Open Sans";
    a {
      font-size: 22px;
      font-weight: bold;
      line-height: 32px;
      letter-spacing: 0px;
      color: #005eb8 !important;
    }
    a:hover {
      font-weight: 500;
      color: #005eb8 !important;
      text-decoration: underline;
    }
  }
  .big-font-link-bg {
    display: flex;
    align-items: center;
    padding: 20px 0px;
  }

  .no-outline {
    font-size: 30px;
    padding: 25px 0px 0px 0px;
  }
  .no-outline:focus {
    outline: none;
  }
  .no-outline-small {
    font-size: 28px;
    padding: 12px 0px 12px 0px;
  }
  .no-outline-small:focus {
    outline: none;
  }
  .p-1 {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 1.25px;
    text-align: left;
    font-weight: 400;
    color: #005eb8;
  }
  .p-2 {
    display: flex;
  }
  .p-2-small {
    display: block;
  }
  .pg-60 {
    padding: 64px 60px;
  }
  .pg-80 {
    padding: 80px;
  }
  .pg-tb-60 {
    padding: 64px 0px;
  }
  .pg-tl-60 {
    padding: 64px 0px 0px 30px;
  }
  .pg-lr-60 {
    padding: 0px 60px;
  }
  .pg-tlr-60 {
    padding: 64px 60px 0px 60px;
  }
  .pg-blr-60 {
    padding: 0px 60px 64px 60px;
  }
  .pg-tbl-60 {
    padding: 64px 0px 64px 60px;
  }
  .pg-top-20 {
    padding: 20px 0px 0px 0px;
  }
  .pg-top-30 {
    padding: 30px 0px 0px 0px;
  }
  .pg-top-60 {
    padding: 64px 0px 0px 0px;
  }
  .pg-bottom-60 {
    padding: 0px 0px 60px 0px;
  }
  .pg-right-35 {
    padding: 0px 40px 0px 0px;
  }
  .pg-left-60 {
    padding: 0px 0px 0px 60px;
  }
  .container {
    vertical-align: middle;
    justify-content: center;
    align-self: center;
  }
  .grey-container {
    background-color: #f6f6f6;
    margin: 0px;
    padding: 0px;
  }
  @media (max-width: 700px) {
    * {
      text-transform: none !important;
      font-family: Roboto;
      margin: 0px;
      padding: 0px;
    }
    h1 {
      font-size: 36px;
      letter-spacing: 0.25px;
      line-height: 44px;
      padding-bottom: 10px;
    }
    h2 {
      font-size: 28px;
      letter-spacing: 0.25px;
      line-height: 36px;
      font-weight: 500;
    }
    .h2-small {
      font-size: 20px !important;
      letter-spacing: 0.25px;
      line-height: 36px;
      font-weight: 400;
    }
    h3 {
      font-size: 20px;
      letter-spacing: 0.25px;
      line-height: 24px;
      font-weight: 500;
      &.h3-color {
        color: #005eb8;
      }
    }
    h4 {
      font-size: 20px;
      letter-spacing: 0.25px;
      line-height: 29px;
      font-weight: 500;
      padding-bottom: 24px;
    }
    h5 {
      font-size: 20px;
      letter-spacing: 0.25px;
      line-height: 29px;
      font-weight: 500;
    }
    h6 {
      font-size: 18px;
      letter-spacing: 0.25px;
      line-height: 24px;
      font-weight: 500;
      padding-bottom: 40px;
    }
    p {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0px;
      font-family: "Open Sans";

      a {
        font-size: 20px;
        font-weight: bold;
        line-height: 28px;
        letter-spacing: 0px;
        padding-left: 10px;
        color: #005eb8 !important;
      }
      a:hover {
        font-weight: 500;
        color: #005eb8 !important;
        text-decoration: underline;
      }
    }
    .no-outline {
      font-size: 20px;
      padding: 12px 0px 12px 0px;
    }
    .no-outline:focus {
      outline: none;
    }
    .big-font-link-bg {
      display: flex;
    }
    .p-2-small {
      display: block;
    }
    .pg-60 {
      padding: 30px;
    }
    .pg-80 {
      padding: 80px;
    }
    .pg-tb-60 {
      padding: 30px 0px;
    }
    .pg-tl-60 {
      padding: 30px 30px 0px 30px;
    }
    .pg-lr-60 {
      padding: 0px 30px;
    }
    .pg-tlr-60 {
      padding: 30px 30px 0px 30px;
    }
    .pg-blr-60 {
      padding: 0px 30px 30px 30px;
    }
    .pg-tbl-60 {
      padding: 30px 0px 30px 30px;
    }
    .pg-top-20 {
      padding: 20px 0px 0px 0px;
    }
    .pg-top-30 {
      padding: 30px 0px 0px 0px;
    }
    .pg-top-60 {
      padding: 30px 0px 0px 0px;
    }
    .pg-bottom-60 {
      padding: 0px 0px 30px 0px;
    }
    .pg-right-35 {
      padding: 0px 40px 0px 0px;
    }
    .pg-left-60 {
      padding: 0px 0px 0px 30px;
    }
    .container {
      vertical-align: middle;
      justify-content: center;
      align-self: center;
    }
  }
}
</style>
