/* eslint-disable no-param-reassign,no-unused-vars */
export const setOnBoardDetails = (state, nextState) => {
  state.onBoardDetails = nextState;
};

export const setFormDetails = (state, nextState) => {
  state.form = nextState;
};

export const setApplicationStatus = (state, nextState) => {
  state.applicationStatus = nextState;
};

export const setAvailabilities = (state, nextState) => {
  state.availabilities = nextState;
};

export const setRefreshBooking = (state) => {
  state.refreshBookingCount = state.refreshBookingCount + 1;
};

export const setDetails = (state, nextState) => {
  state.details = nextState;
};

export const setJobPostFilter = (state, nextState) => {
  state.jobPostFilter = nextState;
};

// Jobs
export const setBookings = (state, nextState) => {
  state.bookings.items = nextState;
};

export const setBookingFilters = (state, nextState) => {
  state.bookingsFilters = {
    ...state.bookingsFilters,
    ...nextState,
  };
};

export const setBookingShowFilters = (state, nextState) => {
  state.bookingShowFilters = nextState;
};

// Job requests
export const setBookingRequests = (state, nextState) => {
  state.bookingRequests.items = nextState;
};

export const setBookingRequestsFilters = (state, nextState) => {
  state.bookingRequestsFilters = {
    ...state.bookingRequestsFilters,
    ...nextState,
  };
};

export const setBookingRequestShowFilters = (state, nextState) => {
  state.bookingRequestShowFilters = nextState;
};

/**
 * Job
 */
export const setBooking = (state, nextState) => {
  state.booking = nextState;
};

/* eslint-enable no-param-reassign */

export const updateBookingFilters = (state, nextState) => {
  state.bookingFilters = nextState;
};
