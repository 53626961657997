export const CarerEndpoints = {
  profile: "/:id/profile",
  profileOpenCareWorker: "/:id/profileOpenCareWorker",
  profileInfo: "/:id/profile/info",
  profileBasicInfo: "/:id/profile/basicInfo",
  profileJobsAppliedFor: "/:id/profile/jobsAppliedFor",
  profilePersonalDetails: "/:id/profile/personalDetails",
  profileExpAndTra: "/:id/profile/expAndTra",
  profileDocuments: "/:id/profile/documents",
  profileQualifications: "/:id/profile/qualifications",
  profileUploadFiles: "/:id/profile/upload-files",
  profileRating: "/:id/profile/rating",
  profileSummary: "/:id/profile/summary",
  profileAvatar: "/:id/profile/avatar",
  profileSubmit: "/:id/profile/submit",
  profileApprove: "/:id/profile/approve",
  profileDisable: "/:id/profile/disable",

  onboardStatus: "/:id/profile/onboard-status",
  applicationStatus: "/:id/profile/application-status",

  search: "/search",
  searchInfo: "/search/info",
  exportCarers: "/export/carers",
  searchLocation: "/search/info",

  bankDetails: "/:id/bank-details",
  disconnectedBankDetailId: "/:id/bank-details/:accountId",
  bankDetailsChange: "/:id/bank-details",
  bankDetailsExpress: "/:id/bank-details/express",

  document: "/:id/document",
  documentGet: "/:id/document/:docId",
  documentDelete: "/:id/document/:docId",

  availabilities: "/:id/availabilities",
  updateAvailability: "/:id/availabilities/:availabilityId",

  review: "/:id/review",

  rateOfPay: "/:id/rate-of-pay",

  allRoles: "/role",
  role: "/:id/role",
  withdrawRole: "/:id/role/:roleId/withdraw",
  approveRole: "/:id/role/:roleId/approve",
  rejectRole: "/:id/role/:roleId/reject",

  getJobPosts: "/job-post",
  jobPostById: "/job-post/:id",
  getJobPostShiftById: "/job-post/shift/:id",
  jobPostApply: "/job-post/apply",
  jobPostCancel: "/job-post/cancel",

  booking: "/:id/booking",
  bookingCount: "/:id/booking/count",
  bookingId: "/:id/booking/:bookingId",
  bookingAccept: "/:id/booking/:bookingId/accept",
  bookingReject: "/:id/booking/:bookingId/reject",
  bookingShiftClockIn: "/:id/booking/:bookingId/shift/:shiftId/clock-in",
  bookingShiftClockOut: "/:id/booking/:bookingId/shift/:shiftId/clock-out",
  bookingShiftCarerReviewTasks:
    "/:id/booking/:bookingId/shift/:shiftId/carer-review-tasks",

  shiftTaskReview: "/:id/job/shift/tasks",
  jobReviewServiceUser: "/:id/job/:jobId/review",

  documentChecklist: "/:id/document-checklist",
};
