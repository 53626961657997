import { RootState, SnackbarData } from "./state";
import { IUser } from "@urbancarecommunity/commons/@types";

export const setApplicationVersion = (state: RootState, nextState: string) => {
  state.applicationVersion = nextState;
};

export const setUser = (state: RootState, nextState: IUser) => {
  state.user = nextState;
};

export const setSnackbars = (state: RootState, nextState: SnackbarData) => {
  state.snackbars = [...state.snackbars, nextState];
};

export const shiftSnackbars = (state: RootState) => {
  state.snackbars = state.snackbars.slice(1);
};

export const updateBookingFilters = (
  state: RootState,
  nextState: RootState["bookingFilters"]
) => {
  state.bookingFilters = { ...state.bookingFilters, ...nextState };
};
