
<div class="public">
  <v-footer dark height="auto" color="#152939" class="pg-bottom-60 mt-4">
    <v-container class="pa-0">
      <div class="pg-lr-60">
        <v-row
          :class="{ 'd-flex flex-column': $vuetify.breakpoint.smAndDown }"
        >
          <v-col cols="12" xs="12" sm="12" md="5" class="pg-top-60">
            <v-card dark flat color="#152939">
              <div>
                <div class="d-flex">
                  <img
                    src="/assets/public/ucclogo.png"
                    style="top: -12px; position: relative"
                    alt="logo"
                  />
                  <span style="font-size: 18px; padding-left: 10px"
                    >Urban Care Community</span
                  >
                </div>
                <a
                  v-for="profile in profiles"
                  :key="profile.icon"
                  :href="profile.link"
                  target="_blank"
                >
                  <v-btn
                    :key="profile.icon"
                    class="mx-1 white--text"
                    color="white"
                    icon
                  >
                    <v-icon size="24px">{{ profile.icon }}</v-icon>
                  </v-btn>
                </a>
                <br />
                <br />
                <p style="font-size: 16px">
                  <b>Newsletter</b>
                </p>
                <form class="form-inline">
                  <div class="form-group">
                    <input
                      type="email"
                      class="form-control"
                      id="inputPassword2"
                      placeholder="Email Address"
                    />&nbsp;
                    <router-link
                      to="/"
                      :class="{
                        'd-flex pt-3': $vuetify.breakpoint.smAndDown,
                      }"
                    >
                      <v-btn light color="white ">Subscribe</v-btn>
                    </router-link>
                  </div>
                </form>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value
                    id="defaultCheck1"
                  />
                  <label class="form-label pa-1">
                    <label for="defaultCheck1">
                      agree to Urban Care Community's
                    </label>
                    <router-link
                      class="terms-link"
                      style="color: #fff"
                      to="/terms-and-conditions"
                      target="_blank"
                    >
                      Terms & Conditions
                    </router-link>
                    and
                    <router-link
                      class="terms-link"
                      style="color: #fff"
                      to="/ucc-privacy-policy"
                      target="_blank"
                    >
                      Privacy Policy
                    </router-link>
                  </label>
                </div>
              </div>
            </v-card>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="2" class="pg-top-60">
            <v-card dark flat color="#152939">
              <p>
                <b>GET STARTED</b>
              </p>
              <router-link to="/care-provider-signup"
                >Care Providers</router-link
              >
              <br />
              <router-link to="/qmo-signup">Monitoring Officer</router-link>
              <br />
              <router-link to="/care-worker-and-nurses-signup"
                >Carers & Nurses</router-link
              >
              <br />
              <router-link to="/contact-us">Contact Us</router-link>
              <br />
              <router-link to="/faq">FAQ</router-link>
            </v-card>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="2" class="pg-top-60">
            <v-card dark flat color="#152939">
              <p>
                <b>COMPANY</b>
              </p>
              <router-link to="/about">About Us</router-link>
              <br />
              <router-link to="/covid19">COVID19 Reponse</router-link>
              <br />
              <router-link to="/ucc-privacy-policy">Privacy</router-link>
              <br />
              <router-link to="/terms-and-conditions"
                >Terms and Conditions</router-link
              >
            </v-card>
          </v-col>
          <v-col
            cols="12"
            xs="12"
            sm="12"
            md="3"
            lg="3"
            xl="3"
            class="pg-top-60"
          >
            <v-card dark flat color="#152939">
              <p>
                <b>RESOURCES</b>
              </p>
              <router-link to="/technology-enabled-care"
                >Technology Enabled Care</router-link
              >
              <br />
              <router-link to="/learning-disability-care-guide"
                >LD Care Guide</router-link
              >
              <br />
              <router-link to="/live-in-care-guide"
                >Live in Care Guide</router-link
              >
              <br />
              <router-link to="/funding-care-guide"
                >Funding Care Guide</router-link
              >
              <br />
              <router-link to="/dementia-care-guide"
                >Dementia Guide</router-link
              >
              <br />
              <router-link to="/nursing-care-guide"
                >Nursing Care Guide</router-link
              >
              <br />
              <router-link to="/policies">Policies</router-link>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <div class="pg-top-30">
        <p :class="{ 'p-small': $vuetify.breakpoint.smAndDown }">
          Urban Care Community is registered in England and Wales Number
          10739712. The Care Quality Commission (CQC) defines companies like
          Urban Care Community as an introductory agency pursuant to the
          Health & Social Care Act 2008
        </p>
      </div>
    </v-container>
  </v-footer>
</div>
