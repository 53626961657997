export const getServiceUser = (state) => {
  const { serviceUsers } = state;

  if (serviceUsers.length === 0) {
    return null;
  }

  return serviceUsers[0];
};

// export const getBookingFilters = (state) => {
//   const bookingFilters = structuredClone(state.bookingFilters || {});
//   Object.keys(bookingFilters).forEach(
//     (key) => bookingFilters[key] === undefined && delete bookingFilters[key]
//   );

//   return bookingFilters;
// };
